import React from 'react'
import { Redirect } from '@reach/router'

interface IndexProps {}

const Index: React.FC<IndexProps> = props => {
  const {} = props

  return <Redirect from="/" to="/home/" noThrow />
}

export default Index
